import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Navigation from './Navigation';
const Opening = React.lazy(() => import('./Opening'));
const Salam = React.lazy(() => import('./Salam'));
const Quote = React.lazy(() => import('./Quote'));
const Mempelai = React.lazy(() => import('./Mempelai'));
const Acara = React.lazy(() => import('./Acara'));
const Galeri = React.lazy(() => import('./Galeri'));
const Doa = React.lazy(() => import('./Doa'));

const VerticalSlider = ({ runOpening }) => {
  const splideRef = useRef(null);
  const [isActiveSplideIdx, setIsActiveSplideIdx] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const delay = useCallback((ms) => {
    return new Promise((resolve) => {
      const id = setTimeout(resolve, ms);
      setTimeoutId(id);
    });
  }, []);

  const cancelDelay = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  const goToSlide = (index) => {
    if (splideRef.current) {
      splideRef.current.go(index);
    }
  };

  // console.log(splideRef.current?.splide?.index)

  const autoSlide = useCallback(
    async (index) => {
      switch (index) {
        case 1: {
          await delay(9200);
          goToSlide(2);
          autoSlide(2);
          break;
        }
        case 2: {
          await delay(7500);
          goToSlide(3);
          autoSlide(3);
          break;
        }
        case 3: {
          await delay(5500);
          goToSlide(4);
          autoSlide(4);
          break;
        }
        case 4: {
          await delay(5500);
          goToSlide(5);
          autoSlide(5);
          break;
        }
        case 5: {
          await delay(18000);
          goToSlide(6);
          autoSlide(6);
          break;
        }
        default: {
          return;
        }
      }
    },
    [delay],
  );

  useEffect(() => {
    goToSlide(1);
    if (runOpening && splideRef.current?.splide?.index === 1) {
      autoSlide(1);
    }
  }, [autoSlide, runOpening]);

  const clickedSlide = (index) => {
    goToSlide(index);
    cancelDelay();
  };

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight - 60);
    };

    // Set the initial screen height
    updateScreenHeight();

    // Update the height if the window is resized
    window.addEventListener('resize', updateScreenHeight);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateScreenHeight);
    };
  }, []);

  return (
    <div>
      <div className="transition-all duration-500 md:w-full">
        <Splide
          ref={splideRef}
          onActive={(val) => {
            setIsActiveSplideIdx(val.index);
          }}
          onDrag={() => {
            cancelDelay();
          }}
          options={{
            type: 'slide',
            pagination: false,
            arrows: false,
            cover: true,
            direction: 'ttb',
            height: `${screenHeight}px`,
            lazyLoad: 'nearby',
            wheel: false,
            releaseWheel: false,
          }}
        >
          <SplideSlide>
            <Opening />
          </SplideSlide>
          <SplideSlide>
            <Salam isActiveSplideIdx={isActiveSplideIdx} runOpening={runOpening} />
          </SplideSlide>
          <SplideSlide>
            <Quote isActiveSplideIdx={isActiveSplideIdx} />
          </SplideSlide>
          <SplideSlide>
            <Mempelai isActiveSplideIdx={isActiveSplideIdx} />
          </SplideSlide>
          <SplideSlide>
            <Acara isActiveSplideIdx={isActiveSplideIdx} />
          </SplideSlide>
          <SplideSlide>
            <Galeri isActiveSplideIdx={isActiveSplideIdx} />
          </SplideSlide>
          <SplideSlide>
            <Doa isActiveSplideIdx={isActiveSplideIdx} />
          </SplideSlide>
        </Splide>
      </div>
      <Navigation goToSlide={clickedSlide} splideRef={splideRef} />
    </div>
  );
};

export default VerticalSlider;
