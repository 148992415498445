import './App.css';
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './pages/Home';
import 'animate.css';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider } from 'react-photo-view';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomePage />,
    },
  ]);

  return (
    <div className="App">
      <PhotoProvider>
        <RouterProvider router={router} />
      </PhotoProvider>
    </div>
  );
}

export default App;
