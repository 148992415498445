import { useState, useRef } from 'react';
import HomeContent from '../component/HomeContent';
import MainContent from '../component/MainContent';

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [runOpening, setRunOpening] = useState(false);
  const [mainCss, setMainCss] = useState('');
  const [isPlayMusic, setIsPlayMusic] = useState(false);
  const musicRef = useRef(null);

  const handleAnimationEnd = () => {
    // Animation ended, now you can hide the content
    setMainCss('!z-0');
    setRunOpening(true);
    playPauseMusic();
  };

  const playPauseMusic = () => {
    if (musicRef.current) {
      if (musicRef?.current?.paused) {
        setIsPlayMusic(false);
        musicRef.current.play();
      } else {
        setIsPlayMusic(true);
        musicRef.current.pause();
      }
    }
  };

  return (
    <>
      <div className={`component preview animate-preview-show h-full select-none overflow-hidden`}>
        <div className={`${!runOpening && 'h-screen'} relative mx-auto max-w-[451px] transition-all duration-500 md:aspect-[0.5] md:w-full`}>
          <div
            style={{
              height: '100vh',
              animation: isOpen ? 'collapseAnimation 1s forwards' : 'none',
            }}
            className={`z-20 ${mainCss}`}
            id="home-content"
            onAnimationEnd={(animate) => {
              if (
                animate.animationName === 'collapseAnimation' &&
                animate.target.id === 'home-content'
              ) {
                handleAnimationEnd();
              }
            }}
          >
            <HomeContent setIsOpen={setIsOpen} />
          </div>
          <div id="main-content" className={`z-10 h-full`}>
            <audio
              src="https://ik.imagekit.io/qt25dqh19/wedding/song.mp3"
              hidden={true}
              loop={true}
              ref={musicRef}
            />
            <div
              className={`${
                runOpening ? 'block' : 'hidden'
              } absolute right-3 top-2/3 z-10 flex h-min w-min rounded-full bg-none text-white transition-all`}
            >
              <div className="m-1 rounded-full bg-white p-1" onClick={() => [playPauseMusic()]}>
                <div className="rounded-full bg-red-500 p-1">
                  {isPlayMusic ? (
                    <svg
                      fill="#FFFFFF"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64.469 80.454"
                      className="h-4 w-4"
                    >
                      <path
                        data-name="Path 1083"
                        d="M22.412 69.248a11.207 11.207 0 0 1-11.206 11.206A11.207 11.207 0 0 1 0 69.248V11.206A11.207 11.207 0 0 1 11.206 0a11.207 11.207 0 0 1 11.206 11.206Z"
                      ></path>
                      <path
                        data-name="Path 1084"
                        d="M64.469 69.248a11.207 11.207 0 0 1-11.206 11.206 11.207 11.207 0 0 1-11.206-11.206V11.206A11.209 11.209 0 0 1 53.263 0a11.207 11.207 0 0 1 11.206 11.206Z"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 72.719 104.978"
                      className="h-4 w-4"
                      fill="#FFFFFF"
                    >
                      <path d="M66.829 66.763a43.6 43.6 0 0 0 5.847-19.2 26.8 26.8 0 0 0-2.827-13.317c-3.319-6.8-9.021-10.62-14.529-14.311-4.111-2.754-8-5.354-10.741-9.069l-.491-.659c-1.623-2.181-3.457-4.652-3.747-6.727A4.083 4.083 0 0 0 36.059.007a4.027 4.027 0 0 0-3.755 4.022v71.957a23.362 23.362 0 0 0-12.116-3.312c-11.129 0-20.19 7.244-20.19 16.152s9.061 16.152 20.19 16.152 20.19-7.244 20.19-16.152V41.759a29.068 29.068 0 0 1 18.72 22.007 22.771 22.771 0 0 1-1.559 2.2 4.034 4.034 0 0 0 6.057 5.33 30.021 30.021 0 0 0 2.964-4.167 2.542 2.542 0 0 0 .266-.371Z"></path>
                    </svg>
                  )}
                </div>
              </div>
            </div>
            <MainContent runOpening={runOpening} />
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;
