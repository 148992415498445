import React from 'react';
import { useSearchParams } from 'react-router-dom';

const HomeContent = ({ setIsOpen }) => {
  const [searchParams] = useSearchParams();

  const guest = searchParams.get('to')?.replace('-', ' - ') || '';

  return (
    <div className="relative flex h-full w-full items-center justify-center whitespace-pre-line">
      {/* Video Background */}
      <div className="absolute inset-0 h-full w-full">
        <video
          autoPlay
          loop
          playsInline
          muted
          disableRemotePlayback
          className="h-full w-full object-cover"
          style={{ opacity: 1 }}
        >
          <source src="https://ik.imagekit.io/qt25dqh19/wedding/home-bg.mp4" type="video/mp4" />
        </video>
      </div>

      {/* Gradient Overlay */}
      <div
        className="absolute inset-0 hidden h-full w-full"
        style={{ background: 'linear-gradient(rgb(169, 127, 113), rgba(169, 127, 113, 0.24))' }}
      ></div>

      {/* Component Content */}
      <div className="component">
        {/* Image 1 */}
        <div className="animate animate-frames-step absolute left-0 top-0 aspect-square h-auto w-[60%]">
          <span
            style={{
              boxSizing: 'border-box',
              display: 'block',
              overflow: 'hidden',
              width: 'initial',
              height: 'initial',
              background: 'none',
              opacity: 1,
              border: '0px',
              margin: '0px',
              padding: '0px',
              position: 'absolute',
              inset: '0px',
            }}
          >
            <img
              src="https://ik.imagekit.io/qt25dqh19/wedding/top-left.png"
              alt="top-left"
              style={{
                position: 'absolute',
                inset: '0px',
                boxSizing: 'border-box',
                padding: '0px',
                border: 'none',
                margin: 'auto',
                display: 'block',
                width: '0px',
                height: '0px',
                minWidth: '100%',
                maxWidth: '100%',
                minHeight: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
            />
          </span>
        </div>

        {/* Image 2 */}
        <div className="animate animate-frames-step-reverse absolute right-0 top-0 aspect-square h-auto w-[60%]">
          <img
            src="https://ik.imagekit.io/qt25dqh19/wedding/top-right.png"
            alt="top-right"
            style={{
              position: 'absolute',
              inset: '0px',
              boxSizing: 'border-box',
              padding: '0px',
              border: 'none',
              margin: 'auto',
              display: 'block',
              width: '0px',
              height: '0px',
              minWidth: '100%',
              maxWidth: '100%',
              minHeight: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </div>

        {/* Image 3 */}
        <div className="animate animate-frames-step absolute bottom-0 left-0 grid aspect-square h-auto w-[60%] place-items-end">
          <img
            src="https://ik.imagekit.io/qt25dqh19/wedding/bottom-left.png"
            alt="bottom-left"
            style={{
              position: 'absolute',
              inset: '0px',
              boxSizing: 'border-box',
              padding: '0px',
              border: 'none',
              margin: 'auto',
              display: 'block',
              width: '0px',
              height: '0px',
              minWidth: '100%',
              maxWidth: '100%',
              minHeight: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </div>

        {/* Image 4 */}
        <div className="animate animate-frames-step-reverse absolute bottom-0 right-0 grid aspect-square h-auto w-[60%] place-items-end">
          <img
            src="https://ik.imagekit.io/qt25dqh19/wedding/bottom-right.png"
            alt="bottom-right"
            decoding="async"
            data-nimg="fill"
            sizes="100vw"
            style={{
              position: 'absolute',
              inset: '0px',
              boxSizing: 'border-box',
              padding: '0px',
              border: 'none',
              margin: 'auto',
              display: 'block',
              width: '0px',
              height: '0px',
              minWidth: '100%',
              maxWidth: '100%',
              minHeight: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </div>

        {/* Text Content */}
        <div className="layout relative text-center top-[-85px]" id="layout10">
          {/* Text 1 */}
          <div className="editable relative z-[1] mx-auto min-h-[1rem] !w-full min-w-[4rem] max-w-[350px] text-center">
            <div
              className="h2 animate animate__animated animate__slower animate__fadeInDown px-2 py-1"
              data-key="text1"
              style={{ color: 'rgb(80, 48, 53)', fontSize: '12px', fontFamily: 'Fahkwang' }}
            >
              <span>THE WEDDING OF</span>
            </div>
          </div>

          {/* Text 2 */}
          <div className="editable relative mx-auto my-[7px] min-h-[1rem] !w-full min-w-[4rem] max-w-[350px] text-center leading-none">
            <div
              className="h1 animate animate__animated animate__slower animate__fadeInDown break-words px-2 py-1 font-medium"
              data-key="text2"
              style={{ color: 'rgb(121, 72, 80)', fontSize: '44px', fontFamily: 'Tangier' }}
            >
              <span>Tasya & Nanda</span>
            </div>
          </div>

          {/* Image 5 */}
          <div className="editable relative mx-auto my-6 min-h-[1rem] w-fit min-w-[4rem] max-w-[350px]">
            <div className="animate animate__animated animate__slower animate__zoomIn">
              <div
                className="relative mx-auto overflow-hidden"
                style={{
                  width: '175px',
                  aspectRatio: '4 / 5',
                  borderRadius: '50% 50% 0px 0px / 43% 43% 0px 0px',
                  border: '0px',
                }}
              >
                <img
                  data-key="image"
                  src="https://ik.imagekit.io/qt25dqh19/wedding/cov-1.jpg"
                  alt="couple"
                  className="pic undefined relative z-0"
                  style={{
                    position: 'absolute',
                    inset: '0px',
                    boxSizing: 'border-box',
                    padding: '0px',
                    border: 'none',
                    margin: 'auto',
                    display: 'block',
                    width: '0px',
                    height: '0px',
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
              <div className="absolute inset-0 scale-125">
                <div>
                  <img
                    src="https://ik.imagekit.io/qt25dqh19/wedding/home-frame.png"
                    alt="frame"
                    decoding="async"
                    data-nimg="fill"
                    sizes="100vw"
                    style={{
                      position: 'absolute',
                      inset: '0px',
                      boxSizing: 'border-box',
                      padding: '0px',
                      border: 'none',
                      margin: 'auto',
                      display: 'block',
                      width: '0px',
                      height: '0px',
                      minWidth: '100%',
                      maxWidth: '100%',
                      minHeight: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Text 3 */}
          <div className="editable relative mx-auto mb-2 min-h-[1rem] !w-full min-w-[4rem] max-w-[350px] text-center">
            <div
              className="h2 animate animate__animated animate__slower animate__fadeInUp px-2 py-1 tracking-widest"
              data-key="text3"
              style={{ color: 'rgb(121, 72, 80)', fontSize: '11px', fontFamily: 'Fahkwang' }}
            >
              <span>TO</span>
            </div>
          </div>

          {/* Text 4 */}
          <div className="editable relative mx-auto block min-h-[1rem] w-fit min-w-[4rem] max-w-[350px] p-1">
            <div
              className="p animate animate__animated animate__slower animate__fadeInUp mb-10 px-2 py-1 text-2xl font-bold leading-none text-[#A97F71]"
              data-key="text4"
              style={{ color: 'rgb(80, 48, 53)', fontSize: '15px', fontFamily: 'Arima' }}
            >
              <span>Our Beloved Guests</span>
              {guest && (
                <>
                  <br />
                  <br />
                  <span>{guest}</span>
                </>
              )}

              <br />
            </div>
          </div>

          {/* Button */}
          <div className="editable relative mx-auto block min-h-[1rem] w-fit min-w-[4rem] max-w-[350px] p-1">
            <div
              className="button animate animate__animated animate__slower animate__fadeInUp mx-auto w-max"
              data-key="button"
              style={{
                color: 'rgb(92, 54, 61)',
                fontSize: '11px',
                fontFamily: 'Fahkwang',
                border: '3px solid rgb(255, 255, 255)',
                borderRadius: '8px',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
              }}
            >
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                className="open-invitation px-6 py-2"
              >
                OPEN INVITATION
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
